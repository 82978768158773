<template>
<div class="scope">
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-modal v-model="showBuyChapterModal" :hide-header="true" :hide-footer="true">
        <BuyChapterModal :novel="detailForModal" />
    </b-modal>
    <!--  -->
    <b-container class="bv-example-row top">
        <div id="topic" class="topic">
            <a-divider type="vertical" class="v-divider" />
            <span class="topicName">ค้นหา: {{topic}}</span>
        </div>
        <b-row class="fiveNovelList">
            <b-col v-if="isLoadData">
                <div class="text-center">
                    <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
                </div>
            </b-col>
            <b-col v-else-if="novels.status!='404'" cols="6" sm="2" v-for="(novel,index) in novels" :key="index">
                <a class="text-decoration-none text-default-n" :href="'/novel/' + novel.novel_id" v-on:click="goToNovelOrChapter(novel, $event)">
                    <b-card img-top tag="article" class="mb-5 bookCardSize">
                        <b-card-text class="triangle" v-if="novel.chapter_price==0">
                            <div class="freeText">ฟรี</div>
                        </b-card-text>
                        <b-card-text class="small text-center noOfEpisode">
                            <div>{{novel.last_ep}}</div>
                        </b-card-text>
                        <b-card-img-lazy class="novelCover" :src="novel.novel_cover" fluid></b-card-img-lazy>
                        <b-card-sub-title class="mb-2 novelName text-center">
                            <v-clamp autoresize :max-lines="1">{{novel.novel_title}}</v-clamp>
                        </b-card-sub-title>
                        <b-card-text class="small text-center">
                            ตอนที่ {{novel.last_ep}}
                        </b-card-text>
                    </b-card>
                </a>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import Login from "./../pages/Login"
import BuyChapterModal from "./../elements/BuyChapterModal"
import VClamp from 'vue-clamp'
import router from './../../router/index'
import {
    mapActions,
    mapGetters
} from 'vuex'
var queryParams = new URLSearchParams(window.location.search);
export default {
    data() {
        return {
            topic: "",
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showLoginModal: false,
            showBuyChapterModal: false,
            detailForModal: {}
        }
    },
    computed: {
        ...mapGetters({
            novels: 'GET_ALL_NOVEL',
            keyword: 'GET_KEYWORD',
            isLoadData: 'GET_LOADING',
            mode: 'GET_MODE'
        })
    },
    components: {
        VClamp,
        Login,
        BuyChapterModal
    },
    mounted() {
        this.firstAction()
        //console.log("AAA"+this.$store.getters.GET_ALL_NOVEL)
    },
    watch: {
        keyword() {
            this.setTopic()
        },
        mode() {
            this.checkMode()
        },
        novels() {
            //console.log('novel change')
            this.checkMode()
        }
    },
    methods: {
        ...mapActions(['SET_SEARCH_NOVEL_WORD']),
        firstAction() {
            if (this.keyword.length > 0) {
                this.SET_SEARCH_NOVEL_WORD(this.keyword)
                queryParams.set("search", this.keyword);
                history.replaceState(null, null, "?" + queryParams.toString());
            } else {
                this.SET_SEARCH_NOVEL_WORD(queryParams.get("search"))
                queryParams.set("search", queryParams.get("search"));
                history.replaceState(null, null, "?" + queryParams.toString());
            }
            this.setTopic()
        },
        setTopic() {
            if (this.keyword.length > 0) {
                this.topic = this.keyword
            } else {
                this.topic = queryParams.get("search")
            }
            //document.title = "ค้นหา: " + this.topic + " | NovelRealm"
        },
        goToNovelOrChapter(novel, e) {
            e.preventDefault();
            router.push("/novel/" + novel.novel_id)
            return false
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('bookCardSize').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('novelName').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            setTimeout(() => {
                document.getElementsByClassName('bookCardSize').forEach(element => {
                    element.classList.add('darkmode')
                });
                document.getElementsByClassName('novelName').forEach(element => {
                    element.classList.add('darkmodeText')
                });
            }, 10);
        }
    },
};
</script>

<style scoped>
.scope {
    padding: 3% 10% 5% 10%;
}

.text-default-n{
    color: inherit;
}

.pageTopic {
    font-size: 20px;
    font-weight: bold;
}

.bookCardSize {
    border-color: #ffffff;
    border-radius: 0px;
    margin: auto;
    transform: translateZ(0px);
    transition-duration: .5s;
}

.bookCardSize:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.fiveNovelList {
    padding-top: 2%;
    padding-bottom: 1%;
    border: 2px solid #ffffff;
}

.novelCover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.card-body {
    padding: 10px;
}

div.col {
    transform: translateZ(0px);
    transition-duration: .5s;
}

div.col:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.novelName {
    font-size: 16 !important;
    color: #000000 !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}

.noOfEpisode {
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 16px;
    background-color: #EE806B;
    border-radius: 0px 0px 6px 6px;
    width: 26px;
    height: 28px;
    padding-top: 8px;
}

.triangle {
    border-style: solid;
    border-width: 0 34px 34px 0;
    border-color: transparent #849eb4 transparent transparent;
    position: absolute;
    right: -1px;
    top: -1px;
}

.freeText {
    position: absolute;
    right: -30px;
    top: 1px;
    color: #ffffff;
    font-size: 13px;
    transform: rotate(45deg);
}

@media screen and (max-width: 500px) {
    .scope[data-v-57109d03] {
        padding: 3% 7% 5% 7%;
    }

    .pageTopic {
        font-size: 14px;
    }

    .novelCover {
        width: 6.75rem;
        height: 10rem;
    }

    .bookCardSize {
        width: 8.1rem;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 5% 20%;
    }
}

.darkmode {
    border-color: #35363A;
    background-color: #35363A;
}

.darkmodeText {
    color: #ffffff !important;
}

.v-divider {
    width: 3px;
    height: 20px;
    background: #EE806B;
}

.top {
    padding-left: 0px;
    padding-right: 0px;
}

.topic {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 3%;
}

.topicName {
    color: #505050;
    font-size: 19px;
    font-weight: bold;
}

.darkmode .topicName {
    color: #505050;
}

.seeAllBtn {
    color: white;
    float: right;
    background-color: #F5D087;
    border-radius: 6px;
    padding: 3px 25px 3px 25px;
    font-size: 14px;
}

.seeAllBtn:hover {
    background-color: #FAC762;
    cursor: pointer;
    font-weight: bold;
}

@media screen and (max-width: 500px) {
    .v-divider {
        height: 15px;
    }

    .topic {
        width: 100%;
        padding: 10px;
    }

    .topicName {
        font-size: 15px;
    }
}
</style>
